import React from "react";
import LazyImage from "components/lazyImage";
import EmbeddedTable from "components/embeddedTable";
import SmartLink from "components/smartLink";
import { INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Title } from "@material-ui/icons";

export default function RichText({ content, className }) {
  const options = {
    renderMark: {
      [MARKS.CODE]: (text) => <sup>{text}</sup>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node) => (
        <SmartLink to={node.data.uri}>{node.content[0].value}</SmartLink>
      ),
      "embedded-asset-block": (node) => (
        <LazyImage
          src={`${node.data.target.fields.file["en-US"].url}?w=1270`}
          height={node.data.target.fields.file["en-US"].details.image.height}
          width={node.data.target.fields.file["en-US"].details.image.width}
          alt={Title}
        />
      ),
      "embedded-entry-block": (node) => (
        <EmbeddedTable
          rows={node.data.target.fields.table["en-US"].tableData}
        />
      ),
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };
  let markup = "";
  if (content) {
    markup = documentToReactComponents(content, options);
  }
  return <div className={className}>{markup}</div>;
}
