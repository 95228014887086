import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import RichText from 'components/richText';
import styles from './article.module.scss';
import isAvailableToUser from 'utils/isAvailableToUser';
import slugify from 'utils/slugify';
import SearchForm from 'components/searchForm';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Breadcrumbs from 'components/breadcrumbs';
import { useUserContext } from 'contexts/userContext';
import { useNavigationLinksContext } from 'contexts/navigationLinksContext';

export default function Article({ data, location }) {
  const user = get(useUserContext(), 'user');
  const resetNavigationLinks = get(
    useNavigationLinksContext(),
    'resetNavigationLinks',
    () => {}
  );
  const addNavigationLink = get(
    useNavigationLinksContext(),
    'addNavigationLink',
    () => {}
  );
  const findNavigationLink = get(
    useNavigationLinksContext(),
    'findNavigationLink',
    () => {}
  );

  const article = data.contentfulArticle;
  const subsections = article.subsections;
  const hash = location.hash;

  useEffect(() => {
    const areParentCategoriesOnNavLinks = article?.category?.filter(
      (category) =>
        findNavigationLink({
          type: 'category',
          name: category.title,
          slug: category.slug,
        })
    );

    if (areParentCategoriesOnNavLinks?.length === 0) {
      resetNavigationLinks();
    }
    addNavigationLink({
      name: article.title,
      slug: article.slug,
      type: 'article',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [subsectionClicked, setSubsectionClicked] = useState(false);

  const handleSubsectionChange = (event, expanded) => {
    setSubsectionClicked(true);

    if (expanded) {
      const newHash = `#${event.currentTarget.id}`;

      window.history.pushState(null, null, newHash);

      if (newHash === hash) {
        setSubsectionClicked(false);
      }
    } else {
      window.history.pushState(
        '',
        document.title,
        window.location.pathname + window.location.search
      );
    }
  };

  const renderSubsections = () => {
    if (!subsections) {
      return null;
    }

    // Filter subsections by license
    const renderedSubsections = subsections
      .filter((s) => isAvailableToUser(user, s.restrictByLicense))
      .map((subsection) => {
        const panelSlug = slugify(subsection.title);
        const expandedProp =
          !subsectionClicked && hash !== '' && panelSlug === hash.slice(1)
            ? { 'data-testid': 'expanded-panel', expanded: true }
            : {};

        return (
          <ExpansionPanel
            key={panelSlug}
            onChange={handleSubsectionChange}
            TransitionProps={{
              'data-testid': `${panelSlug}-panel`,
            }}
            {...expandedProp}
          >
            <ExpansionPanelSummary
              aria-controls='panel1a-content'
              expandIcon={
                <ExpandMoreIcon
                  fontSize='small'
                  viewBox='0 0 24 24'
                  style={{ width: 24, height: 24 }}
                />
              }
              id={panelSlug}
            >
              <Typography>{subsection.title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                <RichText
                  className={styles.body}
                  content={subsection.body.json}
                  alt={subsection.body}
                />
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      });
    return <div>{renderedSubsections}</div>;
  };

  return (
    <>
      <Breadcrumbs />
      <SearchForm />
      <article className={styles.container}>
        <header className={styles.header} title={article.title}>
          <h1 className={styles.title}>{article.title}</h1>
        </header>
        <RichText
          className={styles.body}
          content={get(article.body, 'json', {})}
          alt={get(article.body, 'json', {})}
        />
        {renderSubsections()}
      </article>
    </>
  );
}

export const articleQuery = graphql`
  query ArticleQuery($id: String!) {
    contentfulArticle(id: { eq: $id }) {
      ...ArticleFragment
    }
  }

  fragment ArticleFragment on ContentfulArticle {
    title
    slug
    createdAt
    updatedAt
    body {
      json
      content {
        content {
          value
        }
      }
    }
    thumbnail {
      file {
        url
        details {
          image {
            height
            width
          }
        }
      }
      fluid(maxWidth: 635) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      title
    }
    subsections {
      restrictByLicense
      productImages {
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      title
      body {
        json
        content {
          content {
            value
          }
        }
      }
    }
    category {
      id
      title
      slug
    }
  }
`;
