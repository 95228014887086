function arraysShareAValue(a, b) {
  return a.reduce((acc, curr) => (b.includes(curr) ? true : acc), false);
}

export default function isAvailableToUser(
  user,
  licensesToCheck
) {
  if (!Array.isArray(licensesToCheck) || !licensesToCheck.length) return true;
  if (
    (!user || !user.licenses) &&
    Array.isArray(licensesToCheck) &&
    licensesToCheck.length
  )
    return false;

  const normalizedLicensesToCheck = licensesToCheck.map(l =>
    l.toLowerCase().replace(/\s/g, '_'),
  );
  const activeLicenses = user.licenses.filter(l => l.is_active);
  const activeProducts = [
    ...activeLicenses.map(l => l.product),
  ];

  if (
    normalizedLicensesToCheck.includes('pd_pass') &&
    !activeProducts.includes('pd_pass')
  ) {
    return false;
  }

  return arraysShareAValue(normalizedLicensesToCheck, activeProducts);
}
