import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

export default function LazyImage({
  src,
  className,
  title,
  height,
  width,
  style,
  onLoad,
}) {
  const [containerRef, inView] = useInView({ triggerOnce: true });
  const [isVisible, setIsVisible] = useState(false);

  function handleImageLoad() {
    requestAnimationFrame(() => setIsVisible(true));
    if (onLoad) onLoad();
  }

  const imageStyle = {
    ...style,
    opacity: isVisible && inView ? 1 : 0,
    transition: 'all .6s var(--ease)',
  };

  return (
    <img
      style={imageStyle}
      ref={containerRef}
      src={
        inView
          ? src
          : 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
      }
      alt={'Background section'}
      aria-label={'title'}
      height={height}
      width={width}
      title={title}
      className={className}
      onLoad={handleImageLoad}
    />
  );
}
